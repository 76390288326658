<template>
  <div class='report-table-all-map'>
    <a-table :pagination='true' bordered :columns='columns' :data-source='data' row-key='wallet_address'
             :loading='loading' :scroll='{ x: 1400}'>
      <template slot='stt' slot-scope='item, data, index'> {{ index + 1 }}</template>
      <template slot='walletAddress' slot-scope='item'>
        <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.wallet_address)' />
        <span>{{item.wallet_address}}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import {copyURL} from '@/helpers/common'

export default {
  name: 'ReportsTableAllMap',
  props: {
    query: {
      type: Object,
      default: {}
    },
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'Order',
          width: 140,
          align: 'center',
          scopedSlots: {customRender: 'stt'}
        },
        {
          title: 'Wallet address',
          align: 'center',
          ellipsis: true,
          width: 300,
          scopedSlots: {customRender: 'walletAddress'}
        },
        {
          title: 'Last 1 hour',
          children: [
            {
              title: 'Total',
              dataIndex: 'sum_reward_1',
              width: 120,
              align: 'center'
            },
            {
              title: 'NFT',
              dataIndex: 'count_pet_1',
              width: 120,
              align: 'center'
            },
            {
              title: 'AVG',
              dataIndex: 'avg_1',
              width: 120,
              align: 'center'
            }
          ]
        },
        {
          title: 'Last 12 hour',
          children: [
            {
              title: 'Total',
              dataIndex: 'sum_reward_12',
              width: 120,
              align: 'center'
            },
            {
              title: 'NFT',
              dataIndex: 'count_pet_12',
              width: 120,
              align: 'center'
            },
            {
              title: 'AVG',
              dataIndex: 'avg_12',
              width: 120,
              align: 'center'
            }
          ]
        },
        {
          title: 'Last 24 hour',
          children: [
            {
              title: 'Total',
              dataIndex: 'sum_reward_24',
              width: 120,
              align: 'center'
            },
            {
              title: 'NFT',
              dataIndex: 'count_pet_24',
              width: 120,
              align: 'center'
            },
            {
              title: 'AVG',
              dataIndex: 'avg_24',
              width: 120,
              align: 'center'
            }
          ]
        },
        {
          title: 'Last 48 hour',
          children: [
            {
              title: 'Total',
              dataIndex: 'sum_reward_48',
              width: 120,
              align: 'center'
            },
            {
              title: 'NFT',
              dataIndex: 'count_pet_48',
              width: 120,
              align: 'center'
            },
            {
              title: 'AVG',
              dataIndex: 'avg_48',
              width: 120,
              align: 'center'
            }
          ]
        },
        {
          title: 'Last 72 hour',
          children: [
            {
              title: 'Total',
              dataIndex: 'sum_reward_72',
              width: 120,
              align: 'center'
            },
            {
              title: 'NFT',
              dataIndex: 'count_pet_72',
              width: 120,
              align: 'center'
            },
            {
              title: 'AVG',
              dataIndex: 'avg_72',
              width: 120,
              align: 'center'
            }
          ]
        },
        {
          title: 'Life time',
          children: [
            {
              title: 'Total',
              dataIndex: 'sum_reward',
              width: 120,
              align: 'center'
            },
            {
              title: 'NFT',
              dataIndex: 'count_pet',
              width: 120,
              align: 'center'
            },
            {
              title: 'AVG',
              dataIndex: 'avg',
              width: 120,
              align: 'center'
            }
          ]
        }
      ],
      timeout: null,
    }
  },
  methods: {
    copyURL
  }
}
</script>

<style scoped lang='scss'>
.report-table-all-map {
  .ic-copy {
    color: #52c41a;
    cursor: pointer;
  }
}
</style>
